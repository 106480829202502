import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { EventItem } from './event-item';
import { EventInfo } from './event-info';
import { EventUmbracoItemType } from '../../../types/events.type';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { ContentCard } from '../../../components/layout/ContentCard';
import { useEvents } from './useEvents';
import { Loader } from '../../../components/loader';
import { toast } from 'react-toastify';

export const Events = () => {
  const {
    events,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isFetching,
    error,
  } = useEvents();
  const [currentEvent, setEvent] = useState<EventUmbracoItemType | null>(null);
  const [isInfoOpen, setInfoOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleSelectEvent = (event: EventUmbracoItemType) => {
    setInfoOpen(true);
    setEvent(event);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const eventId = params.get('eventId');
    if (eventId && events) {
      const event = events.pages
        .flatMap((page) => page.items)
        .find((e) => e.id === eventId);
      if (event) {
        setEvent(event);
        setInfoOpen(true);
      }
    }
  }, [location.search, events]);

  useEffect(() => {
    if (currentEvent) {
      navigate(`?eventId=${currentEvent.id}`);
    }
  }, [currentEvent, navigate]);

  const typographyStyle = {
    fontWeight: 'bold',
    fontSize: 14,
    color: '#916d4f',
  };

  const tableCellStyle = {
    px: 0,
    py: 1,
    borderBottom: 0,
  };

  const Content = () => {
    return (
      <>
        <Box maxHeight='calc(calc(100vh - 200px) / 2)' overflow='auto'>
          <Stack py={2} px={2}>
            <Box>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align='left' sx={tableCellStyle}>
                        <Typography sx={typographyStyle}>Date</Typography>
                      </TableCell>
                      <TableCell align='left' sx={tableCellStyle}>
                        <Typography sx={typographyStyle}>Event</Typography>
                      </TableCell>
                      <TableCell align='center' sx={tableCellStyle}>
                        <Typography sx={typographyStyle}>Action</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {events?.pages?.map((eventArray) => {
                      return eventArray?.items?.map(
                        (
                          eventItem: EventUmbracoItemType,
                          eventIndex: number,
                        ) => {
                          return (
                            <EventItem
                              key={`event-${eventIndex}`}
                              event={eventItem}
                              handleSelect={() => handleSelectEvent(eventItem)}
                            />
                          );
                        },
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Stack>
        </Box>
        {currentEvent?.description && (
          <EventInfo
            open={isInfoOpen}
            handleClose={() => setInfoOpen(false)}
            event={currentEvent}
          />
        )}
      </>
    );
  };

  useEffect(() => {
    if (error?.message) {
      toast(`Error: failed to load Upcoming events.`, {
        type: 'error',
      });
    }
  }, [error?.message]);

  return (
    <ContentCard
      icon={<EventAvailableIcon />}
      title='Upcoming Events'
      titleAttributes={{ 'data-tour': 'upcoming-events' }}
      headerCta={
        <>
          {hasNextPage ? (
            <Button
              variant='text'
              sx={{ display: 'flex', gap: 1 }}
              onClick={() => void fetchNextPage()}
            >
              <Typography
                color='primary'
                fontWeight={300}
                fontSize={12}
                textTransform='uppercase'
              >
                View More
              </Typography>
            </Button>
          ) : null}
        </>
      }
      content={
        <>
          {!isFetchingNextPage && isFetching ? (
            <Loader count={10} />
          ) : (
            <Content />
          )}
        </>
      }
    />
  );
};
